import * as React from "react"
import Logo from "../../../images/pp/logo.inline.svg"
import Layout from "../../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import oldOutlook from "../../../images/pp/outlook_old.png"
import newOutlook from "../../../images/pp/outlook_new.png"

import oldKiosk from "../../../images/pp/old_kiosk.png"
import newKiosk from "../../../images/pp/new_kiosk.png"

import Projects from "../../../components/pp_projects"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import Wrapper from '../../../components/wrap';
import Footer from '../../../components/footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Premium Parking">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/head.jpg"
          alt="Premium Parking"
          className="hero-image"
        />
      </AnimationOnScroll>
      <Wrapper className="first">
        <Logo className="project-logo" alt="Premium Parking" />
      </Wrapper>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <h1>
          <small>Premium Parking</small>
          Redesign Validation Kiosks, Outlook Plugin, Pay Machine</h1>
        <p>
          2020 was full of global challenges and business disruptions such as the COVID-19 pandemic and the economic downturn, but it brought us incredible experiences and new ideas. The main goal of the product team is delivering quality parking solutions to clients, and during 2020 we didn't stray from this course.
        </p>
        <strong className="sub-title">Challenges and goals</strong>
        <h3>Staying a reliable partner</h3>
        <p>
          All of these changes affected revenue. Revenue was falling until our clients (businesses) understood how to survive in the new reality. For example, CVS Health and other big retailers used their parking lots for drive-thru testing. Hospitals and other places converted their parking facilities into makeshift field hospitals. Restaurants no longer begin at the front door, but rather in the parking lot, with the rise of "pick-up" delivery. One more big epic win was a partnership with Oklahoma State Parks. All our previous efforts in Business Account and Web-app helped us to integrate it extremely quickly.
          During this time, we couldn't plan long-term, but CPO suggested using low traffic and updating products for touch devices (not mobile apps).
        </p>
        <strong className="sub-title">My role and the team</strong>
        <h3>Oversight and coordination</h3>
        <p>
          At the beginning of the year the product team size was reduced, so I worked alongside with Engineers, and the CPO. I was working several hypotheses and built prototypes (for example: <a target="_blank" rel="noreferrer" href="https://rustemginiyatullin.github.io/pp-ads/web/">video ads</a>) for returning revenue in mobile apps, but they were all unsuccessful.
          <br/>
          Later, I applied the new brand to Outlook Plugin in collaboration with an external dev team from 🇮🇳 India.
          <br/>
          The next quick win was in applying to the new brand for Kiosks. There were several variations for different industries like Hotels and Healthcare. During these updates, I ran quick user testing, and after we added more flexibility to the core workflow.
        </p>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <ReactCompareSlider className="compare"
            itemOne={<ReactCompareSliderImage src={oldOutlook} alt="Old Outlook Plugin" />}
            itemTwo={<ReactCompareSliderImage src={newOutlook} alt="New Outlook Plugin" />}
          />
        </AnimationOnScroll>
        <br/>
        <p>
          And finally, the biggest project during this period was the reworking of the user experience of parking machines (pay machines). We didn't have accumulated usage data, but we had information about the audience. In common case, parking machine users, old school parkers, who pay with cash. Originally, I thought that we could use our Design System, but components behavior for this screen size didn't work (too small for touch). Also, the device's tech stack was different from our Storybook library. Therefore, I've designed UI Kit for Pay Machines with dependency on tokens and used it for designing the new workflow in Pay Machines.
        </p>

        <h3>Final deliverables</h3>
        <p>External development teams updated all of these products. So, the results of my work were high-fidelity prototypes, a collection of new components added to the design system, and documentation for external teams.</p>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={oldKiosk} alt="oldKiosk" />}
            itemTwo={<ReactCompareSliderImage src={newKiosk} alt="newKiosk" />}
          />
        </AnimationOnScroll>
        <div className="center">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/new_kiosk_validator.png"
              alt="Kiosk Validator"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/pay_machine.png"
              alt="Pay Machine"
            />
          </AnimationOnScroll>
        </div>
        <p></p>
      </Wrapper>
      <Projects project="kiosks" />
      </AnimationOnScroll>
      <Footer />
    </Layout>
  )
}

export default IndexPage
